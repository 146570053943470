
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          














































































































































































.custom-navigation {
  border-bottom: 1px solid $grey-light;

  .icon-group-link {
    border: none;
  }

  &.default {
    .__nav-element {
      a {
        border-right: 1px solid $grey-light;
      }
    }
  }

  &.list {
    padding: 0 0 1.5rem 3.5rem;

    @include breakpoint('lg') {
      padding: 0 0 1.5rem 0;
    }

    .spacer {
      display: none;
    }

    .__nav-element {
      border-bottom: none;

      a {
        transition: none;
        border-right: 1px solid black;
        padding: 0 10px 0 0;
        margin: 0 10px 0 0;

        &:hover {
          color: black;
          font-weight: 600;
        }
      }

      &:last-child a {
        border-right: 0;
        padding-right: 0;
      }

      label {
        margin: 0;
        display: inline;
        cursor: pointer;
      }
    }
  }
}


.spacer {
  &__red {
    background-color: $red;
  }

  display: none;

  @include breakpoint('lg') {
    display: block;
  }
}
