
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          



































.timeline {
  list-style: none;
  padding: 0;
  position: relative;
  margin-bottom: 0;
}

.tldate {
  display: block;
  width: 200px;
  color: #ededed;
  margin: 0 auto;
  padding: 3px 0;
  font-weight: bold;
  text-align: center;
}

.timeline li {
  padding-bottom: 25px;
  position: relative;
}

.timeline li:before,
.timeline li:after {
  content: " ";
  display: table;
}
.timeline li:after {
  clear: both;
}
.timeline li:before,
.timeline li:after {
  content: " ";
  display: table;
}

.timeline li .timeline-panel {
  width: 46%;
  float: left;
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
}

.timeline li {
  .liner{
    position: absolute;
    top: 0;
    left: 40px;
    width: 1px;
    background-color: #707070;
    height: 100%;

    @include breakpoint('md'){
      left: 50%;
      transform: translate(-50%);
    }
  }
  .tl-circ {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%);
    text-align: center;
    background: $red;
    color: #fff;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    z-index: 5;
  }

  &:nth-child(even) {
    .tl-circ {
      background: $black;
    }
    .timeline-panel {
        float: right;
    }
  }

  &:first-child{
    .liner{
      top: 30px;

      @include breakpoint('md'){
        display: block;
        top: 50%;
        transform: translate(-50%);
        height: 50%;
      }
    }
  }
  &:last-child{
    .liner{
      height: 25px;

      @include breakpoint('md'){
        transform: translate(-50%);
        height: 50%;
      }
    }
  }
}

.tl-heading h3 {
  margin: 0;
  font-size: 4rem;
  margin-bottom: 2rem;
}

.tl-body p,
.tl-body ul {
  margin-bottom: 0;
}
.tl-body .subtitle {
  font-size: 2.1rem;
  margin-bottom: 2rem;
}

/** media queries **/
@media (max-width: 991px) {
  .timeline li .timeline-panel {
    width: 44%;
  }
}

@media (max-width: 700px) {
  ul.timeline:before {
    left: 40px;
  }

  ul.timeline li .timeline-panel {
    width: calc(100% - 60px);
  }

  ul.timeline li .tl-circ {
    top: 22px;
    left: 40px;
    margin-left: 0;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }
}
