
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          





























































































































































.accordion-list {
  padding-top: 4rem;
  padding-bottom: 4rem;

  &__wrapper {
    border: 1px solid $grey-light;
    margin-bottom: 2.3rem;
  }

  &__toggler {
    cursor: pointer;
    height: 6.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 2.7rem;

    &--title {
      cursor: pointer;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 1em;
    }

    &--icon {
      flex: 0 0 6.8rem;
      width: 6.8rem;
      height: 6.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-left: 1px solid $grey-light;

      .toggle {
        width: 2rem;
        height: 0.2rem;
        position: relative;
        transition: .2s all cubic-bezier(.17, .67, .09, .97);
        background: $dark-grey-b2b;

        .collapsed & {
          &::after {
            transform: rotate(90deg);
            opacity: 1;
          }
        }

        .collapsed & {
          transform: rotate(90deg);
        }

        &::after {
          content: "";
          width: 2rem;
          transition: .2s all cubic-bezier(.17, .67, .09, .97);
          transition-delay: 0.1s;
          height: 0.2rem;
          position: absolute;
          opacity: 0;
          background: $dark-grey-b2b;
        }
      }

    }
  }

  &__content {
    padding: 4rem;
    background-color: $grey-light3;
    border-top: 1px solid $grey-light;
    max-height: 400px;
    overflow: hidden;
    position: relative;
    translate: max-height 0.3s ease-out;

    &.full {
      max-height: 5000px;
      padding-bottom: 8rem;
    }
  }

  .read-more {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, rgba(250, 250, 250, 1) 60%, rgba(255, 255, 255, 0) 100%);
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;

    svg {
      transform: rotate(90deg);
      color: $red;
      width: 1.1rem;
      margin-right: 1rem;
      transition: all 0.2s ease-in;
    }

    &.open {
      svg {

        transform: rotate(-90deg);
      }
    }
  }
}
