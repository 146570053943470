
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          


















































.region-selector{
    padding: 2rem;

    label{
        margin-right: 2rem;
    }
}
