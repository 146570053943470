
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          







































































































































































































/* purgecss start ignore */
.hero {
  background-color: transparent;
  color: white;
  position: relative;
  border-bottom: 1px solid $grey-light;

  .grid-background {
    display: none;
    @include breakpoint('lg'){
      display: flex;
    }
  }

  &.__white {
    color: $white;
  }

  &.__black {
    color: $black;
  }

  &__master-bkg {
    @include o-bkg-cover;
    @include o-bkg-overlay(#ffffff, 0.5);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    &.__black-overlay{
      @include o-bkg-overlay(#000000, 0);
    }
  }

  &__img {
    figure{
      height: 100%;
      min-height: 22vh;
      width: 100%;

      @include breakpoint(sm) {
        min-height: 45vh
      }

      @include breakpoint(md) {
        min-height: 25vh
      }

      /* iPads (landscape) ----------- */
      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
        min-height: 45vh
      }

    }

    margin-top: 5rem;

    @include breakpoint(lg) {
      margin-top: 0;
    }
  }

  h1 {
    color: currentColor;
  }
  h2 {
    color: currentColor;
  }
  p{
    font-size: 2rem;
  }
  .btn{
    margin-top: 1.2rem;
  }

  &__large {

    & .hero__wrapper {
      padding:  6rem 3.5rem;
      @include breakpoint(lg) {
        min-height: 44rem;
        padding: inherit;
      }
      .panel{
        @include breakpoint(lg) {
          min-height: 44rem;
        }
      }
    }
  }

  &__smaller {
    @include breakpoint(xl) {
      padding-top: 5vh;
      padding-bottom: 5vh;
    }
  }
}

.panel {
  display: flex;
  align-items: center;
  position: relative;

  .wrapper{
    position: relative;
    z-index: 2;
    width: 100%;

    @include breakpoint(lg) {
      padding: 3.4rem;
    }

    &.__more-pr{
      @include breakpoint(lg) {
        padding-right: 8.33vw;
      }
    }
  }

  &.justify-content-end{
    .wrapper{
      @include breakpoint(lg) {
        padding-left: 8.3333333vw;
      }
    }
  }
}

.o-rotated-text{
  font-size: 1.1rem;
  color: $grey-light;
  position: absolute;
  left: 4.15vw;
  top: 50%;
  transform: translateX(-50%) rotate(-90deg);
  z-index: 2;
  font-style: normal;
  display: none;

  @include breakpoint('lg'){
    display: block;
  }
}

.align-right {
  margin-left: auto;
}
/* purgecss end ignore */
