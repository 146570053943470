
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          






























































































































.location-input {
  border: none;
  border-bottom: 1px solid #707070;
  font-size: 2.1rem;
  border-radius: 0;
  outline-style: none;
  box-shadow: none;
  padding-left: 0;
  padding-right: 30px;
  height: 4rem;
  &.form-control.is-valid{
    background-image: url('~@/assets/images/check-symbol.svg');
  }
}
.location-input:focus {
  border: none;
  border-bottom: 1px solid #707070;
  border-radius: 0;
  outline-style: none;
  box-shadow: none;
}
.input-container {
  margin-top: 5rem;
  .col-lg{
    position: relative;
  }
  .icon-search{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    @include breakpoint('lg') { 
      right: 8px;
    }
  }
}
.or{
  margin-top: 10px;
    @include breakpoint('lg') { 
      margin-top: 0;
    }
}
@media (max-width: 991.98px) { 
  .btn-find-shop {
    margin: 2rem;
  }
 }
