
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          

























































































































































































































*::-ms-clear {
  display: none;
}

.custom-navigation {
  margin-top: -1px;
  border-top: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;

  .search_input {
    height: 100%;
  }
}

.spacer {
  &__grey {
    background-color: $menu-active;
  }

  display: none;

  @include breakpoint('lg') {
    display: block;
  }
}

.input-group {
  height: 100%;
  width: 100vw;

  @include breakpoint('lg') {
    max-width: 43rem;
  }

  .form-control {
    border-left: 1px solid $grey-light;
    @include breakpoint('lg') {
      border-left: 0;
    }
  }

  input {
    border: none;
    height: 100%;
  }

  input:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .input-group-append {
    width: 6rem;
    padding-left: 1rem;
    border-right: 1px solid $grey-light;

    .icon {
      height: 100%;
    }
  }
}

.custom-navigation__wrapper {
  height: 85px;
}
