
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          
















































































.custom-navigation {
  margin-top: -1px;
  border-top: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;
}

.spacer {
  &__red {
    background-color: $red;
  }

  display: none;

  @include breakpoint("lg") {
    display: block;
  }
}

.icon {
  transition: transform 0.3s ease;
}

.arrow-rotate {
  transform: rotate(450deg);
}

.icon-group-link.__black {
  border-right: 1px solid $grey-light;
  border-left: 1px solid $grey-light;

  @include breakpoint("lg") {
    border-left: 0;
  }

  &:hover, &.active {
    background-color: #fff;
  }
}
