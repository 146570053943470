
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          





















































































































































































.serviceIcon {
  width: 30px;
  height: 25.2px;
}
