
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          



































































.custom-filter {
  border-bottom: 1px solid $grey-light;
}
.spacer {
  &__red {
    background-color: $red;
  }
  display: none;

  @include breakpoint("lg") {
    display: block;
  }
}
.__nav-element {
  border-right: 1px solid $grey-light;

  a {
    height: 7rem;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    &.active,
    &:hover {
      color: white;
      background-color: $menu-active;
    }
  }
}
