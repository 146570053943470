
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          














































































.hide_btn {
  display: none;
}
.space-right {
  margin-bottom: 20px;

    @include breakpoint('sm'){
      margin-right: 20px;
      margin-bottom: 0;
    }
}
