// backgrounds color
.bkg-black {
    background-color: $black;
}
.bkg-red {
    background-color: $red;
}
.pl-per-2 {
    padding-left: 2%;
}
.mh-800-px {
    max-height: 800px;
}
.o-object-fit {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-family: "object-fit: cover; object-position: center;";
}

.pull-lg-1 {
    margin-right: 8.33333333%;
}

.icon-group{
    display: flex;
    align-content: center;
    align-items: center;
}
.icon{
    width: 1.8rem;
    fill: currentColor;
    // transition: all 0.2s ease;
    path{
        fill: currentColor;
        // transition: all 0.2s ease;
    }

    svg{
        width: 1.8rem;
        fill: currentColor; 
        // transition: all 0.2s ease;
        path{
            fill: currentColor;
            // transition: all 0.2s ease;
        } 
    }

    &__sm{
        width: 1.2rem;
        svg{
            width: 1.2rem;
        }
    }
    &__md{
        width: 2.2rem;
        svg{
            width: 2.2rem;
        }
    }
    &__lg{
        width: 2.8rem;
        svg{
            width: 2.8rem;
        }
    }
    &__share{
        height: 1.8rem;
    }
}

.single-card {
    svg {
        width: 5rem;
    }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.search-results {
  background-color: $grey-b2b;
  max-height: calc(100vh - 100px);
  overflow: auto;
  padding: 3rem;

  @include breakpoint('lg') {
    padding: 8rem 0;
  }

  &__item{
    margin-bottom: 3rem;
    em{
      font-size: 2.1rem;
      font-style: normal;
    }
    &:last-child{
      margin-bottom: 0;
    } 
  }
}