
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          


































































































































































































.block-align {
  padding: 3.5rem 0;

  @include breakpoint('mdpi') {
    padding: 8rem 6.5rem 5rem;
  }
}

.primary-nav{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  color: $white;

  @include breakpoint('mdpi') {
    justify-content: flex-start;
  }

  & > div {
    flex: 0 1 100%;
    margin-bottom: 2rem;
    &:last-child{
      margin-bottom: 0;
    }

    @include breakpoint('mdpi') {
      flex: 0 1 auto;
      margin-right: 5vw;
      margin-bottom: 0;
      text-align: left;
      &:last-child{
        margin-right: 0;
      }
    }
  }

  .top-title {
    font-size: 2.1rem;
    color: $white;
    text-decoration: none;
    margin-bottom: 1rem;
    display: inline-block;

    &:last-child{
      margin-bottom: 0;
    }
  }
  .top-item {
    color: currentColor;

  }
}

.footer-top {
  min-height: 227px;
  opacity: 1;
  align-items: stretch;
}

.footer-bottom {
  padding: 4rem 1.5rem 3rem;
  background-color: #F4F4F4;

  @include breakpoint('mdpi'){
    padding: 1rem 6.5rem;
    justify-content: space-between;
    align-items: center;
    min-height: 12rem;
  }
}

.footer-top-left {
  background: $dark-grey-unipro 0% 0% no-repeat padding-box;
}
.footer-top-right {
  background: $white 0% 0% no-repeat padding-box;
}

.social-icons{
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 50vw;
  margin: 0 auto 6rem;

  @include breakpoint('mdpi'){
    margin: 0 auto;
    max-width: 25rem;
  }

  a {
    color: lighten($black, 50%);
    margin-right: 2rem;

    &:last-child{
      margin-right: 0;
    }

    &:hover{
      color: $black;
    }
  }
}

.legals-nav{
  margin-bottom: 4rem;

  display: flex;
  flex-direction: column;
  text-align: center;

  @include breakpoint('mdpi') {
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-end;
  }

  & > a {
    color: lighten($black, 50%);
    margin-bottom: 1rem;

    @include breakpoint('mdpi') {
      margin-bottom: 0;
      margin-right: 3.5rem;

      &:last-child{
        margin-right: 0;
      }
    }
  }
}

.copyright{
  font-size: 1.1rem;
  color: lighten($black, 50%);
  text-align: center;

  p{
    margin-bottom: 0;
  }

  @include breakpoint('mdpi'){
    text-align: left;
  }
}
.newsletter-box{
  padding-left: 3.5rem;
  padding-right: 3.5rem;

  @include breakpoint('lg'){
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .inner{
    text-align: center;
    @include breakpoint('lg'){
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  }
}
.newsletterTitle {
  font-size: 2.1rem;
  font-weight: 600;
  letter-spacing: -0.42px;
  color: #202124;
  opacity: 1;

  margin-bottom: 3rem;
  display: block;
}
