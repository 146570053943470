
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          


















































































































































































.contact-us{
  .has__border {
      border-right: 1px solid $grey-light;
  }
  .wrapper{
      padding: 8rem 8.33333vw 0;

      p{
          font-weight: 600;
          font-size: 2.1rem;
          margin-bottom: 2.5rem;
      }
  }

  .textarea-wrapper{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: flex-end;
    margin-bottom: 0;
    margin-top: 2rem;

    @include breakpoint('lg'){
      margin-top: 0;
    }

    label{
      margin-bottom: 1rem;
      display: block;
      opacity: 0.7;
    }

    .textarea-inner{
      padding: 0 8.33333vw;
      width: 100%;
      position: relative;
      bottom: -4px;

      &.__error{
        textarea{
          border-color: $red;
        }
      }
    }

    textarea{
      width: 100%;
      padding: 2rem;
      border: 1px dashed $grey-light;
      resize: none;
      outline: none;
      height: 15.6rem;
      box-shadow: none;
      -webkit-appearance: none;
    }
  }

  .submit-wrapper{
      height: 8.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 3rem;

      @include breakpoint('lg'){
        padding: 0;
      }
  }
}
