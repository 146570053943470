
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          




















































































.cta-full-width {
  padding-top: 4.4rem;
  padding-bottom: 4.4rem;
  background-color: $grey-b2b;
}
