
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          












































































































.cta_parent {

  @include breakpoint('md') {
    display: inline-flex;
  }

  & > div{
    margin-bottom: 2rem;
    @include breakpoint('md') {
      margin-right: 2rem;
      margin-bottom: 0;
    }

    &:last-child{
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

.simple-content{
  border-top: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;
  &.no-border{
    border-top: none;
    border-bottom: none;
  }

  &.bottom-space {
    margin-bottom: 4rem;

    @include breakpoint("lg") {
      margin-bottom: 8rem;
    }
  }
}

.simple-content {
  padding-top: 7.5rem;
  padding-bottom: 9rem;
  position: relative;

  @include breakpoint('lg') {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  &.btn-only {
    padding-top: 8rem;
    padding-bottom: 6rem;
    a {
      margin-top: 0;
    }
  }

  .wrapper{
    position: relative;
    z-index: 2;
  }

  h2 {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 3rem;
    color: currentColor;
    @include breakpoint('md') {
      max-width: 70vw;
    }
  }

  h2.start{
    text-align: left;
  }

  h2.h2_small{
    font-size: 2.3rem;
  }

  .col-lg-8{
    &.start{

      @include breakpoint('lg') {
        padding-left: 0px;

      }
    }

  }

  p {
    font-size: 2.1rem;
    color: currentColor;

    p{
      color: currentColor;
    }
    &.small{
      font-size: 1.4rem;
    }
  }

  .btn{
    margin-top: 2rem;
  }

  &.title-only{
    padding-top: 2.8rem;
    padding-bottom: 2.8rem;

    h1{
      margin-bottom: 0;
    }
    p{
      margin: 0;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  &__red {
    background-color: $red;
    color: $white;

    h1{
      color: currentColor;
    }
    p{
      color: $white;
    }
  }
  &__grey {
    background-color: $grey-b2b;

    h1, p{
      color: currentColor;
    }
  }
  &__black {
    background-color: $black;
    color: $white;

    h1, p{
      color: currentColor;
    }
  }

  .icon_wrapper {
    z-index: 2;
    margin-top: 4rem;

    @include breakpoint('md') {

    }

    svg {
      width: 1.8rem;
      path {
        fill: currentColor;
      }
    }
  }

  .scroll_dot{
    height: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}
