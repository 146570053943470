
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          








































































































































































































































































































































































































































































































































































































































































































































.min-map-height {
  min-height: 600px;
}
.cursor-pointer{
  cursor: pointer;
}
.hide_btn {
  display: none;
}
.shop-wrapper{
  padding: 4rem 6rem;
  transition: all 0.3s ease-out;
  justify-content: space-between;

  @include breakpoint('lg'){
    flex-wrap: nowrap;
  }

  address{
    margin-bottom: 0.5rem;
  }

  &:hover:not(.selected-shop){
    background-color: $grey-b2b
  }
}
.scroll {
  overflow: scroll;
  -ms-overflow-style: none;
}
.scroll::-webkit-scrollbar {
  display: none;
}
.shop-border {
  border-bottom: 1px solid $grey-light;
  border-right: 1px solid $grey-light;
}
.shop-no-result {
  padding: 4rem 6rem;
}
.selected-shop {
  background-color: $red;
}
.selected-shop div > h3 {
  color: $white;
}

.cta-wrapper {
  display: flex;
  align-items: flex-end;
  text-align: right;
  margin-top: 2rem;


  @include breakpoint('lg'){
    margin-top: 0rem;
  }

  > div{
    .btn:last-child{
      margin-top: 1rem;
    }
  }
}

@media screen and (max-width: 1025px) {
  #shop-list {
    height: 32rem;
  }
}
