
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          





















































































































































































































.maps-bumper {
  width: 100vw;

  @include breakpoint('lg') {
    width: 100%;
  }
}

.contact-infos {
  border-bottom: 1px solid $grey-light;
}

.contact {
  &.black {
    background-color: $black;

    & > * {
      color: white
    }
  }

  &__wrapper {
    padding: 3.5rem;

    @include breakpoint('lg') {
      padding: 4rem;

      .table {
        max-width: 100%;
      }
    }

    @include breakpoint('xl') {
      padding: 7rem;

      .table {
        max-width: 100%;
      }
    }
  }

  &__item {
    margin-bottom: 2rem;
    display: flex;
  }

  .horaires-wrapper {
    flex: 1 1 auto;
    margin-bottom: 3rem;
  }

  .horaires {
    display: flex;
    padding: 1%;

    .hours {
      text-align: right;
    }

    & > div {
      flex: 0 1 50%;

      &.days {
        @include breakpoint('md') {
          flex: 0 1 20%;
        }
        @include breakpoint('lg') {
          flex: 0 1 50%;
        }
        @include breakpoint('xl') {
          flex: 0 1 35%;
        }
      }
    }
  }

  .cta-dir {
    margin-top: 3rem;
  }
}

.label-size {
  font-size: 1.8rem;
  line-height: 1em;

  @include breakpoint('lg') {
    font-size: 2.1rem;
  }

  &.icons {
    padding: 0 0;
    width: 25px;
    margin-right: 1.5rem;
  }
}
