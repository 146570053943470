
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          















































































































































































































































































































































































.sup_title,.date {
  text-transform: uppercase;
  font-size: 1.1rem;
}
.single-card {
  &.black {
    color: $white !important;
    background-color: $black;
    a {
      color: $white !important;
    }
  }

  &.col-1{
    max-width:95%;
    margin: 0 auto;
    border:none !important;

  }
  &.isPromotion{
    @media only screen and (max-width:1025px) {
      flex: 0 1 100%;
      max-width: 100%;
    }
  }

  .icon {
    min-height: 2.5rem;
    @include breakpoint("md") {
      min-height: 7.5rem;
    }
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity:0.25;

    svg {
      width: 5rem !important;
    }
  }

  .figure-wrapper {
    margin: 3.5rem 3.5rem 0;

    @include breakpoint("md") {
      margin: 4rem 4rem 0;
    }
    @include breakpoint("lg") {
      margin: 4rem 4rem 0;
    }
  }

  figure {
    display: block;
    max-width: 100%;
    height: 0;
    padding-top: 50%;
    position: relative;

    img {
      display: block;
      position: absolute;
      max-width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-bottom: 4rem;

    @include breakpoint("lg") {
      padding-bottom: 8rem;
    }

    .content {
      flex: 1 1 auto;
    }
    .desc {
      padding: 4rem 3.5rem 0 3.5rem;
      word-break: break-word;

      @include breakpoint("lg") {
        padding: 4rem 0 0 0;
        width: 66.66%;
        margin: 0 auto;
      }

      h2 {
        margin-bottom: 2rem;
      }
      p {
        margin-bottom: 1.5rem;
        color: currentColor;

        p {
          color: currentColor;
        }
      }
    }

    &.isPromotion{
      padding-bottom: 4rem;

      @include breakpoint("lg") {
        padding-bottom: 4rem;
      }
      .desc {
        padding: 4rem 3.5rem 0 3.5rem;

        h2{
          font-size: 2.4rem;
        }

        @include breakpoint("lg") {
          padding: 4rem 0rem 0 0rem !important;
        }
      }

      .single-card__footer{
        padding: 0 4rem;
      }
    }
  }
  /*
  * Ajustement des padding du wrapper pour
  *le nouveau block 4 cards autoparts/services
  */
  &.p4r {
    .wrapper {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
      .content .desc {
        padding-top: 0;
      }
    }
  }

  &__footer {
    padding: 0 3.5rem;
    margin-top: 1.5rem;
    @include breakpoint('lg') {
      padding: 0 0rem;
      width: 66.66%;
      margin: 1.5rem auto 0;
    }
  }

  &.__centered {
    text-align: center;

    .wrapper {
      padding-top: 4rem;
      @include breakpoint("lg") {
        padding-top: 5rem;
      }
    }

    .desc {
      padding: 2rem 3.5rem 0 3.5rem;

      @include breakpoint("lg") {
        padding: 2rem 0rem 0 0rem;
      }

      @include breakpoint(xl) {
        padding: 2rem 0rem 0 0rem;
      }
      p {
        margin-bottom: 1.5rem;
      }
    }
  }
  &.icon-card{
    .wrapper{
      padding-top: 4rem;
      @include breakpoint(lg) {
        padding-bottom: 8rem;
        padding-top: 6.5rem;
      }
    }
  }
  &.__has-border-full {
    & > .wrapper {
       border-bottom: 1px solid $grey-light;
      @include breakpoint(md) {
        border-right: 1px solid $grey-light;
      }
    }

  }
  &.no-borders{
    & > .wrapper {
       border: none;
    }
  }
  &.__squarred {
    .wrapper {
      .desc {
        padding-top: 4rem;
        @include breakpoint(lg) {
          padding-top: 8rem;
        }
      }
    }
  }
  &.__squarred-with-border {
    .wrapper {
      .content {
        border: 1px solid $grey-light !important;
      }
      .desc {
        padding-top: 4rem;
      }
    }
  }
  &.__image-only {
    .wrapper {
      padding-bottom: 0;

      .content {
        display: flex;
      }
    }

    .figure-wrapper {
      margin: 0;
      display: flex;
      flex: 1 1 auto;
    }

    .figure-inner {
      margin: 0;
      display: flex;
      flex: 1 1 auto;
    }

    figure {
      padding: 0;
      margin: 0;
      width: 100%;
      height: auto;
      max-height: 75vh; //hack ie11 object-fit img
      min-height: 22vh;

      @include breakpoint(lg) {
        min-height: 35vh;
      }

      img{
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: none;
      }
    }
  }
  &.__panel-inverted {
    background-color: $black;
    .desc {
      h2,
      p {
        color: $white;
      }
    }
  }
  &.__icon-top-right {
    .wrapper{
      padding-top: 0;
      padding-bottom: 8rem;
    }
    .icon {
      position: absolute;
      top: 1.5rem;
      right: 1rem;
      width: 2.5rem;
      height: 2.5rem;
      min-height: inherit;
      padding-top: 0;
      @include breakpoint(md) {
        height: 3.5rem;
        width: 3.5rem;
        top: 3.5rem;
        right: 3.5rem;
      }
    }
  }
  &.__padding-b:not(.pb){
    .wrapper{
      padding-bottom: 0;
    }
  }
  &.col-5ths{
    min-height: 50vw;
    @include breakpoint(md) {
      min-height: 50vw;
    }
    @include breakpoint(lg) {
      min-height: 20vw;
    }
  }
  .category-tile{
    padding: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    border-right: none !important;
    border-bottom: 1px solid $grey-light;
    border-left: 1px solid $grey-light;
    &:nth-of-type(odd) {
      @include breakpoint(lg) {
        border-right: 1px solid  $grey-light !important;
      }
   }
      @include breakpoint(lg) {
        border-left: none;
     }


    .__link-wrapper{
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      text-align: center;
      text-decoration: none;
      align-items: center;
    }

    .desc{
      width: 100%;
      padding: 0 2rem 1.5rem;

      @include breakpoint(lg) {
        padding: 0 4rem 1.5rem;
      }

      h2{
        font-size: 1.8rem;
      }
    }

    figure{
      margin: 1.5rem 0 2rem 0;
      width: 60%;
      height: 30vw;
      display: flex;
      align-items: flex-end;
      text-align: center;
      overflow: hidden;

      @include breakpoint(md) {
        height: 30vw;
      }

      @include breakpoint(lg) {
        height: 10vw;
      }


      img{
        width: 100%;
        height: auto;
        max-width: 300px;
        margin: 0 auto;
      }

    }

    &.__logo-only{
      a {
        justify-content: center;
        figure{
          align-items: center;
        }
      }
    }

    .single-card__footer{
      padding: 0;
    }
  }
}
