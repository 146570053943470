
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          











































































.tiles {

  &__renderblock {
    margin-bottom: 2rem;
    img {
      margin-bottom:2rem;
      width: 100%;

      @include breakpoint("lg") {
        padding-right: 4rem;
      }
    }
  }

  &__wrapper::v-deep {
    .row {
      height: 100%;
    }

    .__image-only {
      figure {
        min-height: 25rem;
      }
    }
  }

  .tiles__wrapper.__has-border {
    border-bottom: 1px solid $grey-light;    
  }

  .tiles__wrapper.__has-border {
    .__image-only{
      border-right: 1px solid $grey-light;
    }

  }
  .bottom-space {
    margin-bottom: 4rem;

    @include breakpoint("lg") {
      margin-bottom: 8rem;
    }

    &.__has-border {
      border-top: 1px solid $grey-light;
    }
  }
}
