@mixin o-bkg-cover {
  background-size: cover;
  background-position: center;
}
@mixin o-bkg-overlay($overlayColor: #000000, $opacity: 0.7, $zindex: 1) {
  &::before {
    content: "";
    background-color: $overlayColor;
    opacity: $opacity;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zindex;
  }
}
@mixin u-vh-hack {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

@mixin breakpoint($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
