
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          



































































.app-body {
  p a {
    transition: all 0.3s ease;
    font-weight: bold;

    &:hover {
      color: #000;
      font-weight: bold;
    }
  }
}

.app-container {
  transition: padding 0.2s ease;
  padding-top: 45px;
  @include breakpoint('lg') {
    padding-top: 100px;
  }
}

.categorie-nav__active {
  .app-container {
    padding-top: 117px;
    @include breakpoint('lg') {
      padding-top: 180px;
    }

    @include breakpoint('xl') {
      padding-top: 181px;
    }
  }
}
