
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          
































































.block-download {
  padding-top: 7.5rem;
  padding-bottom: 9rem;
  position: relative;

  @include breakpoint('lg') {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  &__title {
    margin-bottom: 4rem;
  }

  .section-cards {
    border-top: 1px solid $grey-light;
  }
}
