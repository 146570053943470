
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          












































.custom-navigation{
  border-top: 1px solid $grey-light;
  border-bottom: 1px solid $grey-light;
}
.spacer{
    &__grey{
      background-color: $menu-active;
    }

    display: none;

    @include breakpoint('lg') {
      display: block;
    }
}
.__nav-element {
  height: 7rem;
  display: flex;

  & > div {
    
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 4.33vw;
    min-width: 6rem;
    opacity: 0.7;
    transition: opacity 0.3s ease;

    &:hover{
      opacity: 1;
    }
  }
}
