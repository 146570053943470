// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  min-width: 150px;
  min-height: 40px;
  width: 100%;
  height: auto;
  font-size: 1.3rem;
  border-radius: 0;
  background-color: transparent;
  color: currentColor;
  border: 1px solid currentColor;
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  position: relative;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;

  // &::after {
  //   position: absolute;
  //   content: "";
  //   width: 0;
  //   height: 105%;
  //   top: 0;
  //   right: -5%;
  //   z-index: -1;
  //   background: $black;
  //   transform: skewX(-10deg);
  //   transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  // }

  &:hover,&.active{
    color: $menu-active;
    border-color: $menu-active;
    background-color: transparent;
    // &:after {
    //   left: -5%;
    //   width: 108%;
    // }
  }

  @include breakpoint('sm') {
    width: auto;
  }
  
  &__smaller{
    min-width: auto;
    padding: 1rem;
    min-width: 13rem;
  }

  &__inverted {
    background-color: $black;
    color: $white;
    border-color: $black;

    &:hover{
      color: $white;
      border-color: $menu-active;
      background-color: $menu-active;
    }
  }

  &__white {
    color: $white;
    border-color: $white;

    &:hover{
      color: $grey-light;
      border-color: $grey-light;
    }
  }
}

.icon-group-link{
  height: 7rem;  
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5rem;
  text-decoration: none;
  border-bottom: 1px solid $grey-b2b;
  position: relative;

  @include breakpoint('lg') {
    border-left: 1px solid $grey-b2b;
    border-bottom: none;
  }

  &:hover{
    text-decoration: none;
  }

  label {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
    cursor: pointer;
    text-align: center;
    flex-grow: 2;
    line-height: 1.1;

    @include breakpoint('lg') {
      text-align: left;
      flex-grow: 1;
    }
  }

  .icon-group {
    height: 100%;
    position: absolute;
    right: 2.5rem;
    top: 50%;
    transform: translateY(-50%);

    @include breakpoint('lg') {
      position: static;
      top: unset;
      right: unset;
      transform: none;
    }

    svg {
      margin-right: 0.8rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.__black{
    background-color: $black;
    color: $white;

    &:hover,&.active{
      background-color: $menu-hover;
      color: $black;
    }
  }
}
.form-control.is-invalid {
  background-image: url('~@/assets/icons/cross.svg');
}

// Settings Burger Menu
// ==================================================
$hamburger-padding-x: 0px !default;
$hamburger-padding-y: 0 !default;
$hamburger-layer-width: 20px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 5px !default;
$hamburger-layer-color: $white !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Hamburger
// ==================================================
.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  outline: none !important;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    } @else {
      opacity: $hamburger-hover-opacity;
    }
  }

  &.is-active {
    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter;
      } @else {
        opacity: $hamburger-active-hover-opacity;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
      width: 20px !important;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: calc($hamburger-layer-height / -2);

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  width: 12px !important;

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

/*
 * Spin
 */
.hamburger--spin {
  .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    }

    &::after {
      transition: bottom 0.1s 0.25s ease-in,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-inner {
      transform: rotate(225deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out,
        transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}