
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          






































































































































.section-cards {
  .row {
    &.content_area {
      @include breakpoint("lg") {
        width: calc(100% - 7rem);
      }
      margin: 0 auto;
    }
  }

  &.white {
    background-color: $white;
  }

  &.black {
    background-color: $black;
  }

  &.grey {
    background-color: $grey-light;
  }

  &.red {
    background-color: $red;
  }

  &.bottom-space {
    padding-bottom: 4rem;

    @include breakpoint("lg") {
      padding-bottom: 8rem;
    }

    + .section-cards.borders {
      border-top: 1px solid $grey-light;
    }
  }

  &.borders {
    .single-card {
      & > .wrapper {
        @include breakpoint("md") {
          border-right: 1px solid $grey-light;
        }
        border-bottom: 1px solid $grey-light;
      }
    }
  }

  &.no-border {
    .single-card {
      & > .wrapper {
        border: 0;
      }
    }
  }

  &.half-border {
    .single-card {
      @include breakpoint("md") {
        &:nth-child(2n+1) {
          &::after {
            content: '';
            background-color: $grey-light;
            width: 1px;
            height: calc(100% - 16rem);
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }

        &:last-child {
          &::after {
            content: none;
          }
        }
      }

      @include breakpoint("lg") {
        .wrapper {
          padding: 0 4rem;
        }

        &::after {
          content: '';
          background-color: $grey-light;
          width: 1px;
          height: calc(100% - 16rem);
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }

      & > .wrapper {
        border: 0;
        border-top: 1px solid $grey-light;
        padding-bottom: 4rem;
      }

      .desc {
        padding-top: 4rem !important;
        width: 100%;
      }

      &__footer {
        width: 100%;
      }
    }
  }


  @include breakpoint("lg") {
    padding-top: 0;
  }

  &.__centered {
    text-align: center;
  }

  &.is-summary {
    @include breakpoint("lg") {
      margin-bottom: -2rem;
    }

    .single-card {
      flex: 0 0 100%;
      max-width: 100%;
      @include breakpoint("lg") {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
      }

      ::v-deep {
        p:not(.date) {
          font-size: 2.1rem;
        }
      }
    }
  }
}
