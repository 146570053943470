
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          




































































































































.preview {
  .app-body {
    min-height: 70vh;
  }

  .header_wrapper {
    top: 70px;
  }

  .app-body {
    padding-top: 70px;

    section:nth-child(2) {
      padding-top: 70px;
    }
  }

  .breadcrumb-nav {
    padding-top: 70px;
  }

  .errorContentType {
    padding: 70px 2rem;
    font-size: 2.1rem;
  }
}

.selectPreviewRegion {
  position: fixed;
  z-index: 9;
  width: 100%;
  height: 70px;
  background-color: white;
}
