
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          























.back-to-top {
    cursor: pointer;
    width: 8rem;
    height: 8rem;
    background-color: $dark-grey-b2b;
    display: flex;
    flex-direction: column;
    color: $white;
    justify-content: center;
    align-content: center;
    align-items: center;
    svg {
        transform: rotate(-90deg);
        width: 1.8rem;
        margin-bottom: 1rem;
    }
    &__text {
        color: $white;
        font-size: 1.2rem;
        text-align: center;
        padding: 0 3px;
    }
}
