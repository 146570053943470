
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          




























.black {
  color: $white;
  background-color: $black;
}
.red{
  color: $white;
  background-color: $red;
}
.grey{
  color: $black;
  background-color: $grey-light;
}
// block 5 spacer
.spacer {
  opacity: 1;
}
