
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          






































































































































.search-wrapper{
  min-height: 7rem;
}
.search-text-col {
  background-color: $grey-b2b
}
.search-text {
  text-align: center;
  font: Regular 11px/19px Work Sans;
  letter-spacing: -0.22px;
  color: #9B9B9B;
  opacity: 1;
  text-transform: uppercase;
  font-size: 1.5rem;
}
.search-border {
  border: 1px solid $grey-light;
  opacity: 1;
  min-width: 32rem;
  min-height: 7rem;
}
.search-inner{
  border-bottom: 1px solid $grey-light;
  border-top: 1px solid $grey-light;
}
.custom-navigation {
  border-bottom: 1px solid $grey-light;
}
.icon-group-link{
  border-right: 1px solid $grey-light;
  border-left: 1px solid $grey-light;
}
.spacer {
  &__grey{
    background-color: $grey-b2b;
  }
  display: none;

  @include breakpoint("lg") {
    display: block;
  }
}
.icon{
    transition: all 0.3s ease;
}
.arrow-rotate {
  transform: rotate(450deg);
}
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font: SemiBold 18px/21px Work Sans;
  font-size: 19px;
  letter-spacing: -0.36px;
  color: #1F1B1E;
  opacity: 1;
}
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 22px;
  border: 1px solid $black;
  opacity: 1;
}
.container-checkbox:hover input ~ .checkmark {
  @include breakpoint('lg'){
    background-color: #ccc;
  }
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.container-checkbox .checkmark:after {
  left: 10px;
  top: -15px;
  width: 11px;
  height: 31px;
  border: solid #69676f;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(36deg);
  transform: rotate(45deg);
}
