
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          










































































.section-tabs {
  border-left: 1px solid $grey-light;
  margin-bottom: -1px;
  border-right: 1px solid $grey-light;

  @include breakpoint('lg') {
    border-right: 0;
  }

  &.__centered {
    text-align: center;
  }
}

.hide_tab {
  display: none;
}
