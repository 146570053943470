
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          




























































































































































































































































































































































































































































.google-map {
  margin: 0 auto;
  background: gray;
  height: 50vh !important;

  @include breakpoint('lg'){
    height: inherit !important;
  }
  
}

.min-size {
  min-height: 50rem;
}
