
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          

























































































































































































































































.single-card-clickable {

  @include breakpoint("lg") {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .sup_title,
  .date {
    text-transform: uppercase;
    font-size: 1.1rem;
  }
  figure {
    max-width: 100%;
    position: relative;
    margin: 3.5rem 3.5rem 0;

    @include breakpoint("md") {
      margin: 4rem 4rem 0;
    }
    @include breakpoint("lg") {
      margin: 4rem 4rem 0;
    }

    img {
      width: 100%;
      height: auto;
      max-height: 400px;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-bottom: 4rem;

    @include breakpoint("lg") {
      padding-bottom: 8rem;
    }

    .content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .desc {
      padding: 4rem 3.5rem 0 3.5rem;
      word-break: break-word;

      @include breakpoint("lg") {
        padding: 4rem 0 0 0;
        width: 86.66%;
        margin: 0 auto;
      }

      h2 {
        margin-bottom: 2rem;
      }
      h3 {
        margin-bottom: 0;
        font-size: 2rem;

        @include breakpoint("lg") {
          font-size: 1.8rem;
        }

        @include breakpoint("xl") {
          font-size: 2.5rem;
        }
      }
      h4 {
        margin-top: 2rem;
      }
      p {
        margin-bottom: 1.5rem;
        color: currentColor;

        p {
          color: currentColor;
        }
      }
    }
  }
  /*
  * Ajustement des padding du wrapper pour
  *le nouveau block 4 cards autoparts/services
  */
  &.p4r {
    .wrapper {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
      .content .desc {
        padding-top: 0;
      }
    }
  }

  &.__centered {
    text-align: center;

    .wrapper {
      padding-top: 2rem;
      @include breakpoint("lg") {
        padding-top: 0;
      }
    }

    .desc {
      padding: 2rem 2.5rem 0 1.5rem;

      @include breakpoint("lg") {
        padding: 2rem 0rem 0 0rem;
      }

      @include breakpoint(xl) {
        padding: 2rem 0rem 0 0rem;
      }
      p {
        margin-bottom: 1.5rem;
      }
    }
  }

  &.__squarred-with-border {
    .wrapper {
      /* min-height: 23vw; */
      figure {
          margin: 2rem 4rem 0;
          max-height: 400px;
      }
      .content {
        border: 1px solid $grey-light !important;
      }
      .desc {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }
  }

  .__link-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    align-items: center;
  }
}
