
            @import "@/styles/utilities/_variables.scss";
            @import "@/styles/utilities/_mixins.scss";
            @import "@/styles/utilities/_helpers.scss";
          




























































































































































































































.wrapper {
  .btn {
    @include breakpoint("md") {
      
    }
  }
  
  .input-wrapper {

    @include breakpoint("md") {

    }
  }
}
::v-deep .modal-content{

  padding: 3.5rem;

  @include breakpoint("mdpi") {
    padding: 8rem 8rem 6rem;
  }
  .modal-header {
    border: 0;
    padding: 0;
    margin-bottom: 1.5rem;

      @include breakpoint("mdpi") {
        margin-bottom: 3rem;
      }
  }

  .modal-body{
    padding: 0;
  }

  .close{
    text-indent: -5000px;
    background-image: url('~@/assets/icons/cross.svg');
    background-repeat: no-repeat;
    background-position: center;

    position: absolute;
    top: 15px;
    right: 25px;

    @include breakpoint("mdpi") {
      top: 30px;
      right: 40px;
    }
  }

  .btn{
    margin-top: 2rem;
  }
}
.thank-you{
  padding: 4rem 0;
}
